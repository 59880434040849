import React from "react";
import styles from "../style.module.scss";

const Burger = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="red"
  >
    <path
      className={styles.line}
      d="M 4 10 l 24 0 c 2 0 2 -0.896 2 -1 c 0 -0 0 -1 -2 -1 l -24 0 c -2 0 -2 0.896 -2 1 c 0 0 -0 1 2 1 "
      fillRule="nonzero"
    />
    <path
      className={styles.line}
      d="M 4 18 l 24 0 c 2 0 2 -0.896 2 -1 c 0 -0 0 -1 -2 -1 l -24 0 c -2 0 -2 0.896 -2 1 c 0 0 -0 1 2 1 Z"
      fillRule="nonzero"
    />
    <path
      className={styles.line}
      d="M 4 26 l 24 0 c 2 0 2 -0.896 2 -1 c 0 -0 0 -1 -2 -1 l -12 0 c -2 0 -2 0.896 -2 1 c 0 0 -0 1 2 1 Z"
      fillRule="nonzero"
    />
  </svg>
);
export default Burger;
