import React from "react";
import styles from "./style.module.scss";
import { LogoSVG } from "assets/svg";

const Logo = () => {
  return (
    <div className={styles.mainLogo}>
      <LogoSVG />
    </div>
  );
};

export default Logo;
