import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Box, Hidden, Typography } from "@mui/material";

import Logo from "components/Logo";
import Link from "components/Link";
import Button from "components/Button";
import cs from "classnames";

import style from "./style.module.scss";
import Burger from "./components/Burger";
import { HeartSVG } from "assets/svg";
import theme from "theme";
import { useStoreActions, useStoreState } from "easy-peasy";

import useEnableNavigation from "hooks/useEnableNavigation";
import useFavorites from "hooks/useFavorites";

const translate = (name) => {
  if (!name) return "primary";
  switch (name) {
    case "Rynek pierwotny":
      return "primary";
    case "Rynek wtórny":
      return "secondary";
    default:
      return "primary";
  }
};

const NavMenu = ({ link, name, items, currentMarket }) => {
  const active = translate(name) === currentMarket;
  return (
    <li className={style.nav__menu_item}>
      <Link href={`/${link}`} className={style.link}>
        <Hidden mdDown>
          <Typography
            color={active ? "secondary" : "primary"}
            component="span"
            className={style.linkIcon}
          >
            {">"}
          </Typography>
        </Hidden>
        <Typography
          component="span"
          color="primary"
          fontWeight={active ? 800 : 500}
          className={style.linkSpan}
        >
          {name}
        </Typography>
      </Link>
      <ul className={style.nav__submenu}>
        {items?.map(({ link: linkChild, name, enable = "0" }) => {
          if (enable !== 1) return null;

          return (
            <li key={`nav_${name}`} className={style.nav__submenu_item}>
              <Link href={`/${link}&realestate_type_id[]=${linkChild}`}>
                <Typography
                  textTransform="capitalize"
                  component="span"
                  color={"primary"}
                  fontWeight={500}
                  className={style.nav__submenu_item_text}
                  whiteSpace="nowrap"
                >
                  {name}
                </Typography>
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};
const NavMenuMobile = ({ link, name, items, setOpenMenu }) => {
  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      textTransform="uppercase"
      mt={2}
    >
      <Box width="100%" onClick={() => setOpenMenu(false)}>
        <Link
          href={`/${link}`}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Typography component="span" color="secondary" fontWeight={800}>
            {name}
          </Typography>
        </Link>
      </Box>
      <Box
        component="ul"
        px={0}
        width="100%"
        mt={2}
        borderTop={`1px solid ${theme?.palette?.grey[200]}`}
      >
        {items?.map(({ link: linkChild, name, enable = 0 }) => {
          if (enable !== 1) return null;
          return (
            <Box
              component="li"
              key={`nav_${name}`}
              sx={{ listStyle: "none" }}
              py={2}
              width="100%"
              borderBottom={`1px solid ${theme?.palette?.grey[200]}`}
              onClick={() => setOpenMenu(false)}
            >
              <Link
                href={`/${link}&realestate_type_id[]=${linkChild}`}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  textTransform="capitalize"
                  component="span"
                  color={"primary"}
                  fontWeight={500}
                  whiteSpace="nowrap"
                  width="100%"
                >
                  {name}
                </Typography>
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const Header = (props) => {
  const [openMenu, setOpenMenu] = useState(false);

  const { getEnableNavigation, getLink } = useEnableNavigation();
  const { countFavorites } = useFavorites();

  const openComparisonModal = useStoreActions(
    (state) => state.modals.openComparisonModal
  );
  const currentMarket = useStoreState((state) => state?.appInfo?.currentMarket);
  const errorApi = useStoreState((state) => state?.appInfo?.errorApi);

  if (errorApi)
    return (
      <header
        className={cs(style.mainHeader, {
          [style.open]: openMenu,
        })}
      >
        <Container
          style={{ maxWidth: theme.breakpoints.values.lg }}
          className={style.container}
        >
          <h1>
            <Link href="/" title={"Expander"} ariaLabel="strona główna">
              <Logo />
            </Link>
          </h1>
        </Container>
      </header>
    );

  return (
    <header
      className={cs(style.mainHeader, {
        [style.open]: openMenu,
      })}
    >
      <Container
        style={{ maxWidth: theme.breakpoints.values.lg }}
        className={style.container}
      >
        <h1>
          <Link href="/" title={"Expander"} ariaLabel="strona główna">
            <Logo />
          </Link>
        </h1>
        <Hidden mdUp>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex="1"
            onClick={() => setOpenMenu((prev) => !prev)}
          >
            <Burger className={style.burger} />
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex="1"
            className={cs(style.menuContainer, {
              [style.open]: openMenu,
            })}
          >
            <Box component="nav" className={style.nav} ml={16} mx={18}>
              <ul>
                <NavMenu
                  currentMarket={currentMarket}
                  link={"investments?market=primary"}
                  name={"Rynek pierwotny"}
                  items={[
                    {
                      name: "mieszkania",
                      link: getLink("Flat"),
                      enable: getEnableNavigation("Flat"),
                    },
                    {
                      name: "domy",
                      link: getLink("House"),
                      enable: getEnableNavigation("House"),
                    },
                    {
                      name: "lokale",
                      link: getLink("Local"),
                      enable: getEnableNavigation("Local"),
                    },
                  ]}
                />
                <NavMenu
                  currentMarket={currentMarket}
                  link={"investments?market=secondary"}
                  name={"Rynek wtórny"}
                  items={[
                    {
                      name: "mieszkania",
                      link: getLink("Flat"),
                      enable: getEnableNavigation("Flat", "secondary_market"),
                    },
                    {
                      name: "domy",
                      link: getLink("House"),
                      enable: getEnableNavigation("House", "secondary_market"),
                    },
                    {
                      name: "lokale",
                      link: getLink("Local"),
                      enable: getEnableNavigation("Local", "secondary_market"),
                    },
                    {
                      name: "działki",
                      link: getLink("Terrain"),
                      enable: getEnableNavigation(
                        "Terrain",
                        "secondary_market"
                      ),
                    },
                    {
                      name: "Inne",
                      link: getLink("other"),
                      enable:
                        getEnableNavigation("Garage", "secondary_market") ||
                        getEnableNavigation("Hall", "secondary_market"),
                    },
                  ]}
                />
              </ul>
            </Box>

            <div className={style.buttonsContainer}>
              <Box>
                <Button
                  data-testid="comparisionModal"
                  variant="text"
                  onClick={() => {
                    openComparisonModal(true);
                  }}
                >
                  <Box position="relative">
                    <Box
                      position="absolute"
                      top="-3px"
                      left="-8px"
                      width="20px"
                      height="20px"
                      borderRadius={50}
                      bgcolor="#de1f24"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        color="white"
                        lineHeight="0.7rem"
                        fontSize="0.7rem"
                      >
                        {countFavorites}
                      </Typography>
                    </Box>
                    <HeartSVG sx={{ padding: 0 }} />{" "}
                  </Box>
                </Button>
              </Box>
            </div>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            position="fixed"
            left={0}
            top={72}
            backgroundColor="white"
            width="100%"
            px={4}
            maxHeight="85vh"
            sx={{
              overflowY: "auto",
              zIndex: -1,
              transition: ".3s ease-in-out",
              transform: `translateY(${openMenu ? 0 : -100}%)`,
            }}
          >
            <Box component="nav" width="100%">
              <Box component="ul" mt={4} px={0} borderTop="2px solid red">
                <NavMenuMobile
                  setOpenMenu={setOpenMenu}
                  currentMarket={currentMarket}
                  link={"investments?market=primary"}
                  name={"Rynek pierwotny"}
                  items={[
                    {
                      name: "mieszkania",
                      link: getLink("Flat"),
                      enable: getEnableNavigation("Flat"),
                    },
                    {
                      name: "domy",
                      link: getLink("House"),
                      enable: getEnableNavigation("House"),
                    },
                    {
                      name: "lokale",
                      link: getLink("Local"),
                      enable: getEnableNavigation("Local"),
                    },
                  ]}
                />
                <NavMenuMobile
                  setOpenMenu={setOpenMenu}
                  currentMarket={currentMarket}
                  link={"investments?market=secondary"}
                  name={"Rynek wtórny"}
                  items={[
                    {
                      name: "mieszkania",
                      link: getLink("Flat"),
                      enable: getEnableNavigation("Flat", "secondary_market"),
                    },
                    {
                      name: "domy",
                      link: getLink("House"),
                      enable: getEnableNavigation("House", "secondary_market"),
                    },
                    {
                      name: "lokale",
                      link: getLink("Local"),
                      enable: getEnableNavigation("Local", "secondary_market"),
                    },
                    {
                      name: "działki",
                      link: getLink("Terrain"),
                      enable: getEnableNavigation(
                        "Terrain",
                        "secondary_market"
                      ),
                    },
                    {
                      name: "Inne",
                      link: getLink("other"),
                      enable:
                        getEnableNavigation("Garage", "secondary_market") ||
                        getEnableNavigation("Hall", "secondary_market"),
                    },
                  ]}
                />
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Button
                  sx={{ padding: 0, justifyContent: "flex-start" }}
                  variant="text"
                  onClick={() => {
                    openComparisonModal(true);
                    setOpenMenu(false);
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <HeartSVG sx={{ padding: 0 }} /> <Box pl={2}>Ulubione</Box>
                  </Box>
                </Button>

                <Box
                  width="20px"
                  height="20px"
                  borderRadius={50}
                  bgcolor="#de1f24"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    color="white"
                    lineHeight="0.7rem"
                    fontSize="0.7rem"
                  >
                    {countFavorites}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </Container>
    </header>
  );
};
Header.displayName = "Header";

Header.propTypes = {
  siteTitle: PropTypes.string,
};
Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
